<template>
  <div class="trip_activities-list" :key="key">
    <div class="text-h5 font-weight-bold mb-5">Trip activities</div>
    <div v-for="item in data" :key="item.id" class="d-flex align-center mt-3">
      <v-card outlined flat class="width100 rounded-lg pt-4 px-5 d-flex" height="82">
        <div class="d-flex flex-md-row flex-column multi-direct width100">
          <v-text-field
            class="field46 width100 me-md-3"
            v-model="item.title_en"
            placeholder="Title"
            :error-messages="titleEnErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
          <v-text-field
            class="field46 width100 mt-md-0 mt-n2 ms-md-3"
            v-model="item.title_ar"
            placeholder="عنوان"
            :error-messages="titleArErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
        </div>
        <div class="width100 ml-md-15 ml-4">
          <v-btn @click="update(item)" depressed outlined color="primary" class="rounded mr-2" width="100" height="38">
            <v-icon color="primary">mdi-check</v-icon><span class="text-body-2">Save</span>
          </v-btn>
          <v-btn @click="openRemove(item)" depressed outlined color="error" class="rounded" width="48" height="38" icon>
            <img class="error-filter" src="@/assets/icon/delete.svg" alt="icon" />
          </v-btn>
        </div>
      </v-card>
    </div>
    <v-btn @click="modal = true" depressed class="primary rounded mt-5 ml-6" width="136" height="30">
      <span class="text-body-2">Add new</span>
    </v-btn>
    <v-dialog v-model="modal" max-width="700">
      <v-card flat>
        <div class="font-weight-bold py-4 text-center">New trip activity</div>
        <v-card flat outlined class="px-10 py-3">
          <div class="my-5">Provide information in English and Arabic</div>
          <v-row>
            <v-col cols="6" class="py-0">
              <v-textarea
                class="field46"
                v-model="modalData.title_en"
                :error-messages="titleEnErrors"
                placeholder="Enter title (eng)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="1"
                row-height="36"
              ></v-textarea>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-textarea
                dir="rtl"
                class="field46"
                :error-messages="titleArErrors"
                v-model="modalData.title_ar"
                placeholder="Enter title (ar)"
                outlined
                dense
                required
                color="primary"
                auto-grow
                rows="1"
                row-height="36"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card>
        <div class="text-end py-5 px-10">
          <v-btn @click="modal = false" depressed class="me-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
          <v-btn @click="createItem" depressed class="primary rounded text-body-2" width="106" height="30">Done</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalRemove" max-width="480">
      <v-card flat color="pa-4">
        <div class="text-h6 font-weight-bold">Do you really want to remove {{ modalData.title_en }}?</div>
        <div class="text-end mb-1 mt-5">
          <v-btn @click="modalRemove = false" depressed class="me-3 rounded" outlined width="106" height="30" text color="gray lighten-1">
            <span class="black--text text-body-2">Cancel</span>
          </v-btn>
          <v-btn @click="removeItem" depressed class="error rounded text-body-2" width="106" height="30">Remove</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      modalRemove: false,
      modalData: {},
      key: 1,
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'done') {
        this.$notify({
          title: 'Done!',
          message: 'Trip activities item was successfully update',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    openRemove(i) {
      this.modalRemove = true;
      this.$nextTick(() => {
        this.modalData = Object.assign({}, i);
      });
    },
    async getData() {
      await this.$store.dispatch('getTripActivities').catch((e) => {
        this.getDataError(e);
      });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
    async createItem() {
      this.error = [];
      const data = new Object();
      this.modalData.title_en ? (data.title_en = this.modalData.title_en) : '';
      this.modalData.title_ar ? (data.title_ar = this.modalData.title_ar) : '';
      await this.$store
        .dispatch('createTripActivities', data)
        .then((res) => {
          this.modal = false;
          this.modalData = {};
          this.data.push(res.data);
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async update(item) {
      this.error = [];
      const data = new Object();
      data.title_en = item.title_en || '';
      data.title_ar = item.title_ar || '';
      await this.$store
        .dispatch('updateTripActivitiesItem', { id: item.id, data: data })
        .then(() => {
          this.notifi('done');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.modal ? '' : this.notifi('500');
        });
    },
    async removeItem() {
      this.error = [];
      await this.$store
        .dispatch('removeTripActivitiesItem', this.modalData.id)
        .then(() => {
          const idx = this.data.findIndex((i) => i.id == this.modalData.id);
          this.modalRemove = false;
          this.data.splice(idx, 1);
          ++this.key;
          this.modalData = {};
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.modal ? '' : this.notifi('500');
        });
    },
  },
  watch: {
    modal() {
      this.error = [];
      this.modalData = {};
    },
  },
  computed: {
    data() {
      return this.$store.getters.tripActivities;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setTripActivities');
  },
};
</script>

<style lang="scss">
#app .trip_activities-list {
  .v-input {
    max-width: 395px;
  }
  .v-btn.primary--text {
    background: var(--v-primary-lighten3);
  }
  .v-btn.error--text {
    background: var(--v-error-lighten5);
  }
}
</style>
